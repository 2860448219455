function fetchConfig () {
  return {
    headers: {
      token: window.BARREL.sbdApiAccessToken
    }
  }
}

async function fetchAPI (path, config) {
  const url = new URL(window.BARREL.sbdApiUrl)
  url.pathname = path

  const response = await fetch(url, config)

  if (!response.ok) {
    throw response
  }

  return response.json()
}

export async function addToWishlist (payload) {
  const config = fetchConfig()
  config.method = 'POST'
  config.headers['Content-Type'] = 'application/json'
  config.body = JSON.stringify(payload)

  return fetchAPI('/api/addWishListItem', config)
}

export async function getWishlist () {
  const config = fetchConfig()
  const pageSize = 1000

  return fetchAPI(`/api/wishlist/list/${window.BARREL.customerId}?pageSize=${pageSize}`, config)
}

export async function removeFromWishlist (payload) {
  const config = fetchConfig()
  config.method = 'POST'
  config.headers['Content-Type'] = 'application/json'
  config.body = JSON.stringify(payload)

  return fetchAPI('/api/removeWishListItem', config)
}
