/**
 * Converts USD dollars to USD cents
 * @param {string} amount formatted amount e.g. $1.00
 * @return {number} amount in cents
 */
export const dollarsToCents = (amount) => {
  return Math.round(
    100 * parseFloat(typeof amount === 'string' ? amount.replace(/[$,]/g, '') : amount)
  )
}

/**
 * Check element in viewport
 *
 * @param {*} elem Element
 * @returns {boolean} true/false
 */

export const isInViewport = (elem) => {
  const rect = elem.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export const docOffset = (el) => {
  let rect = el.getBoundingClientRect()
  let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export const handleize = str => str.replace(/[ /_]/g, '-').toLowerCase()

/**
 * Decodes a string that has been encode through the 'url_encode' Shopify filter
 *
 * @param {*} str encoded string
 * @returns {string} decoded string
 */
export const decode = str => decodeURIComponent(str).replace(/\+/g, ' ')
