export function setHeightProductCard(reset = false) {
  const productGrids = document.querySelectorAll('.js-product-grid, .js-recommended-products, .js-carousel')
  productGrids?.forEach(item => {
    if (item.closest('.hidden')) return
    const productCards = item.querySelectorAll('product-card')
    let maxHeightCardBlock = 0
    let maxHeightButtonBlock = 0
    productCards?.forEach(card => {
      const cardContentEl = card.querySelector('.js-product-card-content')
      const cardButtonEl = card.querySelector('.js-product-card-button')

      if (reset) {
        cardContentEl.style.removeProperty('height')
        card.style.removeProperty('height')
      }

      maxHeightCardBlock = cardContentEl?.clientHeight > maxHeightCardBlock ? cardContentEl?.clientHeight : maxHeightCardBlock
      maxHeightButtonBlock = cardButtonEl?.clientHeight > maxHeightButtonBlock ? cardButtonEl?.clientHeight : maxHeightButtonBlock
    })

    // set height product card
    productCards?.forEach(card => {
      const cardContentEl = card.querySelector('.js-product-card-content')
      cardContentEl.style.height = maxHeightCardBlock + 'px'
      card.style.height = maxHeightCardBlock + maxHeightButtonBlock > 122 ? 122: maxHeightButtonBlock + 'px'
    })
  })
}
