import debounce from 'lodash/debounce'
import { handleize } from '@/scripts/utils'

export const scrollHandle = debounce(() => {
  handleListProductInViewport()
}, 200, { leading: false, trailing: true })

export const handleListProductInViewport = () => {
  const products = document.querySelectorAll('.in-viewport')
  let data = []
  products.forEach(item => {
    data.push(item.dataset)
  })

  const listId = data.map(item => item.id)
  const sessionData = JSON.parse(sessionStorage.getItem('data-layer')) || []

  const sameIds = listId.length === sessionData.length && listId.every(function(value, index) { return value === sessionData[index]})
  if (!sameIds) {
    const viewItemList = []
    data.forEach(item => {
      const datalayerProduct = item.datalayerProduct
      const productIndex = item.index
      if (datalayerProduct) {
        const productData = JSON.parse(decodeURIComponent(datalayerProduct))
        if (productIndex) {
          productData.index = productIndex
        } else {
          productData.index = 0
        }

        productData.item_list_id = handleize(item.placement)
        productData.item_list_name = item.placement

        viewItemList.push(getProductData(productData))
      }
    })

    pushViewItemListToDataLayer(viewItemList)
    sessionStorage.setItem('data-layer', JSON.stringify(listId))
  }
}

export const handleLinksClick = els => {
  if (!els) return
  els.forEach(item => {
    item.addEventListener('click', () => {
      window.dataLayer = window.dataLayer || []
      const component = item.dataset.component || 'Header'
      const position = item.dataset.position
      const name = item.dataset.name || item.innerText
      window.dataLayer.push({
        'event': 'navigation',
        'parameter_1': component,
        'parameter_2': position,
        'parameter_3': name
      })
    })
  })
}

export const handleRemoveLineItem = (product) => {
  if (!product) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  setTimeout(() => {
    window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [].concat(getProductData(product))
      }
    })
  }, 500);
}

export const handleAddLineItem = (product) => {
  if (!product) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  setTimeout(() => {
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [].concat(getProductData(product))
      }
    })
  }, 500);
}

export const addGiftProduct = (items) => {
  window.dataLayer = window.dataLayer || [];
  setTimeout(() => {
  dataLayer.push({
    event: "add_to_cart",
    parameter_1:"cart",
    ecommerce: {
      items: items
    }
  })
  }, 500);
}

export const getCategory = (product) => {
  let obj = {}
  product.productCollections.forEach((item, index) => {
    if (index === 0) {
      obj['item_category'] = item.title
    } else if (index < 5) {
      obj[`item_category${index + 1}`] = item.title
    }
  })
  return obj
}

export const handleAddToCartClick = (product, index, parameter, collections, variant) => {
  let firstVariant = variant ? variant : product.variants[0]
  let item_id = firstVariant.sku ? firstVariant.sku : firstVariant.id
  let items = [{
    item_id: item_id,
    item_name: product.title,
    index: index,
    item_brand: product.vendor,
    ...collections,
    item_variant: firstVariant.title,
    price: firstVariant.price || firstVariant.price.amount,
    quantity: 1,
    item_list_id: product.item_list_id,
    item_list_name: product.item_list_name
  }]

  setTimeout(() => {
  window.dataLayer.push({
    'event':'add_to_cart',
    parameter_1: parameter,
    ecommerce: {
      items: items
    }
  })
  }, 500);
}


export const handleWhereToBuyClick = (product, text = '') => {
  if (!product) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    'event':'where_to_buy',
    parameter_1: text,
    ecommerce: {
      items: [].concat(getProductData(product))
    }
  })
}

export const viewOrderHistory = (orders) => {
  if (!orders) return
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    'event':'view_order_history',
    ecommerce: {
      orders: orders
    }
  })
}

const getProductData = (product) => {
  const productData = {
    item_id: product.item_id || product.variants[0].sku,
    item_name: product.title.replace("'", ''),
    index: parseInt(product.index),
    item_brand: product.vendor,
    item_list_id: product.item_list_id || "featured_products",
    item_list_name: product.item_list_name || "Featured Products",
    item_variant: product.variants[0].title,
    price: (product.price / 1).toFixed(2).toString(),
    quantity: product.datalayer_quantity || 1
  }

  if (product.salsifyCategory && product.salsifyCategory !== 'null' && product.salsifyCategory.length > 0) {
    const categories = product.salsifyCategory.split('>')
    categories.forEach((item, i) => {
      let index = i + 1
      if (index >= 5) return
      if (index === 1) {
        productData['item_category'] = item
      } else {
        productData[`item_category${index}`] = item
      }
    })
  } else {
    const collections = product.collections || product.productCollections
    if (collections && collections.length) {
      collections.forEach((cat, i) => {
        let index = i + 1
        if (index >= 5) return
        if (index === 1) {
          productData['item_category'] = cat.title
        } else {
          productData[`item_category${index}`] = cat.title
        }
      })
    }
  }

  return productData
}

export function createAccount() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'global_account_creation',
    'parameter_1': 'account_creation_start',
  })
}

export function createAccountComplete() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'global_account_creation',
    'parameter_1': 'account_creation_complete',
  })
}



  export function pushSelectProductItem (product) {
  window.dataLayer = window.dataLayer || []
  setTimeout(() => {
    dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [].concat(getProductData(product))
      }
    });
  }, 500);
}

export function mapResultItemToDataLayerViewItemList (product) {
  return getProductData(product)
}

export function pushViewItemListToDataLayer (viewItemList) {
  if (!viewItemList || !viewItemList.length) return
  window.dataLayer.push({
    event: "view_item_list",
    ecommerce: {
      items: viewItemList
    }
  });
}

export function pushDataBundleProduct (items) {
  window.dataLayer = window.dataLayer || [];
  setTimeout(() => {
    dataLayer.push({
      event: "add_to_cart",
      parameter_1: "PDP",
      ecommerce: {
        items: items
      }
    })
  }, 500);
}

export function viewCartDataLayer (openFromMiniCart = false) {
  window.fetch(`/cart?view=ajax`)
    .then(response => response.json())
    .then(data => {
      if (!data.cartItem.length) {
        return
      }

      window.dataLayer = window.dataLayer || []
      let cartItem = data.cartItem
      if (openFromMiniCart) {
        cartItem = cartItem.map(item => {
          return item
        })
      }

      setTimeout(() => {
        dataLayer.push({
          event: 'view_cart',
          ecommerce: {
            value: data.cartTotalPrice,
            items: cartItem
          }
        })
      }, 500);


    })
    .catch(error => {
      console.log(error)
    })
}

export const pushAddToWishlist = (product) => {
  window.dataLayer.push({
    'event':'add_to_wishlist',
    ecommerce: {
      value: (product.price / 1).toFixed(2).toString(),
      items: [].concat(getProductData(product))
    }
  })
}

export function pushSearchDatalayer(type = 'typed', searchTerm) {
  window.dataLayer = window.dataLayer || [];
  setTimeout(() => {
    window.dataLayer.push({
      'event' : 'search',
      'parameter_1' : type,
      'parameter_2' : searchTerm
    })
  }, 500);
}

export function pushProductRegistration(sku) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event' : 'global_product_registration',
    'parameter_1' : 'product_registration_complete',
    'parameter_2': sku ,//Model number of the product passed while filling the form"
  })
}

export function pushExploreFaqs(data) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event' : 'explore_faqs',
    'parameter_1' : data.pageType,
    'parameter_2': data.pageName,
    'parameter_3': data.faqTitle
  })
}

setTimeout(() => {
  handleListProductInViewport()
}, 500)

document.addEventListener('scroll', () => {
  scrollHandle()
})
