/**
 * Theme.js
 */

import '../scripts/accessibility'
import '../scripts/custom-event'
import '../scripts/aos'
import '@/scripts/flying-scripts'
import { getWishlist } from '@/services/wishlist'
import revive from '@/scripts/revive'
import { handleLinksClick, handleWhereToBuyClick } from '@/scripts/datalayer'
import {updateWarningMessageOpenNewTab} from "@/scripts/accessibility"
import { setHeightProductCard } from "@/scripts/product-grids"
import _debounce from 'lodash/debounce'
import '@/scripts/epigraph'

// Islands
const islands = import.meta.glob('../../modules/**/*.(js|jsx)')
revive(islands)

const els = document.querySelectorAll('.js-datalayer-button')
handleLinksClick(els)

// globals
;(async () => {
  // wishlist
  if (window.BARREL.customerId) {
    window.BARREL.wishlist = await getWishlist()
    const event = new window.CustomEvent('wishlistLoaded', { detail: window.BARREL.wishlist })
    setTimeout(() => {
      window.document.dispatchEvent(event)
    }, 500)

  }
})()

setTimeout(() => {
  updateWarningMessageOpenNewTab()
}, 3000)

const epigraphARs = document.querySelectorAll('epigraph-ar')
epigraphARs.forEach((epigraphAR) => {
  epigraphAR.addEventListener('epigraph-valid-product', (e) => {
    const el = e.target
    const shadow = el.shadowRoot
    setTimeout(() => {
      const modelViewer = shadow.querySelector('model-viewer')
      const posterEl = modelViewer.querySelector('.default-poster-foreground')
      if (posterEl) {
        posterEl.setAttribute('aria-label', modelViewer.alt)
      }
    },2000)
  })
})

window.addEventListener('resize', _debounce(() => {
  setHeightProductCard(true)
}, 100))
