const loadScriptsTimer = setTimeout(loadScripts, parseInt(window.YETT_TIMEOUT))
const userInteractionEvents = ["scroll", "mousedown", "mousemove", "click", "keydown", "touchstart"]
window.YETT_BLACKLIST.length && userInteractionEvents.forEach(function (event) {
  window.addEventListener(event, triggerScriptLoader, { passive: true })
})

function triggerScriptLoader (event) {
  if (event && !event.isTrusted) return
  loadScripts()
  clearTimeout(loadScriptsTimer)
  userInteractionEvents.forEach(function (event) {
    window.removeEventListener(event, triggerScriptLoader, { passive: true })
  })
}

function loadScripts () {
  window.yett.unblock()
}

