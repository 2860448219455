import { addToWishlist, getWishlist } from '@/services/wishlist'

async function getListProduct(skuList) {
  let queryTerms = skuList.map(item => item.sku).join(' OR ')
  let skuArr = skuList.map(item => item.sku).map(item => item.toLowerCase())
  let response = await fetch(`/search?q=${queryTerms}&view=sku&type=product`)
  let skus = await response.json()
  const items = skus?.products.filter(item => skuArr.includes(item.sku.toLowerCase())).map(item => {
    return {
      productId: item.id,
      customerId: window.BARREL.customerId,
      handle: item.handle,
      sku: item.sku,
      title: item.title,
      variantId: item.variantId,
      qty: 1
    }
  })
  return items
}

async function refreshWishlist() {
  window.BARREL.wishlist = await getWishlist()
  const event = new window.CustomEvent('wishlistLoaded', { detail: window.BARREL.wishlist })
  window.document.dispatchEvent(event)
}

async function addToWishlistClickHandler(product) {
  try {
    await addToWishlist(product).then(data => {
      if (data.errors) {
        throw data
      }
    }).catch(error => {
      throw error
    })

  } catch (error) {
    throw error;
  }
}

window.epgIsLoggedIn = () => {
  return !!window.BARREL.customerId
}

window.epgAddToFavorites = async (skuList, epgGarageShareId) => {
  console.log('skuList', skuList);
  console.log('epgGarageShareId', epgGarageShareId);
  if (!window.BARREL.customerId) {
    const redirectLink = window.location.href + '?epgGarageShareId=' + epgGarageShareId
    localStorage.setItem("epgRedirectLink", redirectLink);
    window.open('/account/register', '_blank').focus();
    return
  }

  try {
    const products = await getListProduct(skuList) || []
    if (products.length != skuList.length) {
      throw {
        status: 'errors',
        message: 'SKU is not found'
      }
    }

    for (let k = 0; k < products.length; k++) {
      await addToWishlistClickHandler(products[k])
    }

    return {
      status: 'Added',
      message: "Added to Favorites"
    }
  } catch (e) {
    console.error(e)
    return {
      status: 'errors',
      message: 'The product has not been added to Favorites'
    }
  }

  await refreshWishlist()
}
